import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'ca'}>
      <section id="one">
        <header className="major">
          <h2>Estimulació musical en escoles bressol</h2>
        </header>
        <p>
          Les <strong>sessions i tallers d'estimulació musical</strong> en escoles bressol estan pensades per estimular el desenvolupament dels infants a través del joc sonor, les cançons, el moviment i l’expressió musical. La música és una eina poderosa per afavorir el benestar emocional, el vincle, el llenguatge i l’aprenentatge en la primera infància.
        </p>
        <p>
          Aquests tallers també estan dirigits als educadors/es dels infants, que estaran presents durant les sessions. D’aquesta manera, podran adquirir recursos musicals que podran utilitzar en el seu dia a dia, afavorint una continuïtat en l’estimulació musical. A més, contribuirà a enfortir el vincle mestre-infant, creant una connexió més profunda i una millor experiència d’aprenentatge.
        </p>
        <h2>Què proposem?</h2>
        <p>
          <ul>
            <li><strong>Sessions vivencials i actives</strong> adaptades a cada franja d’edat.</li>
            <li><strong>Exploració sonora i rítmica</strong> mitjançant instruments, materials, la veu i el cos.</li>
            <li><strong>Cançons i jocs musicals</strong> per estimular el llenguatge i la motricitat.</li>
            <li><strong>Moviment i expressió corporal</strong> per integrar la música de manera natural a través del cos, fomentant la coordinació i la creativitat.</li>
            <li><strong>Moments de relaxació i connexió</strong> per afavorir l’autoregulació emocional.</li>
          </ul>
        </p>
        <p>
          Les sessions es poden organitzar de manera <strong>setmanal</strong>, <strong>quinzenal</strong> o com <strong>tallers puntuals</strong> segons les necessitats de cada escola. Cada sessió està dissenyada amb un enfocament <strong>respectuós i vivencial</strong>, adaptant-se a les necessitats de cada grup i escola.
        </p>
        <p>
          Contacta’m per conèixer més detalls i crear junts una experiència musical única per als més petits!
        </p>
      </section>
      <section id="three">
        <h2>Propers tallers</h2>
        <p>
          Per consultar els tallers d'estimulació musical que es realitzaran properament,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            clica aquí!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
